import React, { useState, useEffect, useRef } from 'react';
import { fetchEventSource } from '@microsoft/fetch-event-source';

import '../index.css'; // Import your CSS styles

const Chat: React.FC = () => {
  const [inputValue, setInputValue] = useState<string>('');

  const [chatList, setChat] = useState<ChatResponseItem[]>([]);
  const chatWindowRef = useRef(null);

  const resultRef = useRef<string>('');

  let newLineTracker: string | undefined;
  let codeBlockTracker: string | undefined;
  let codeBlockStarted: boolean | undefined;
  const sendMessage = async () => {
    if (!inputValue) return;

    try {
      setInputValue('');
      const updatedMessages = [...chatList.map(item => ({ content: item.content, role: item.role })), { content: inputValue, role: 'user' }];

      const serverBaseURL = 'https://lsgd.frnd.ai/api/v1/brain/stream/aifer-mithra';

      const headers = {
        Accept: '*/*',
        authority: 'chat.openai.com',
        accept: 'text/event-stream',
        'accept-language': 'en-US,en;q=0.9,ml;q=0.8',
        'Content-Type': 'application/json',
      };

      const body = {
        model: 'gpt-3.5-turbo',
        stream: true,
        messages: updatedMessages,
      };

      const updatedChatList: ChatResponseItem[] = [...chatList, { content: inputValue, role: "user" }];

      setChat(updatedChatList);

      const controller = new AbortController();
      await fetchEventSource(`${serverBaseURL}`, {
        signal: controller.signal,
        method: 'POST',
        body: JSON.stringify(body),
        headers,
        onopen: async (res) => {
          if (res.ok && res.status === 200) {
            console.log('Connection made ', res);
          } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
            console.log('Client side error ', res);
            controller.abort();
          }
        },
        onmessage(event) {
          if (event.data === '``') {
            codeBlockTracker = '``';
          } else if (event.data === '`') {
            codeBlockTracker = `${codeBlockTracker}\``;
          } else if (event.data === '```') {
            codeBlockTracker = '```'
          } else if (codeBlockTracker === '```') {
            codeBlockStarted = !codeBlockStarted;
            if (event.data != "") {
              event.data = "";
            }
            resultRef.current = resultRef.current.replace(
              '```',
              codeBlockStarted ? '<div class="code-snippet"><p class="code-copy"></p><pre><code class="language-markup">' : '</code></pre></div>'
            );
            codeBlockTracker = undefined;
          } else {
            codeBlockTracker = undefined;
          }

          if (codeBlockStarted && event.data === '' && !codeBlockTracker) {
            event.data = '\n';
          }

          if (event.data.includes("<")) {
            event.data = event.data.replaceAll('<', '&lt;');
          }

          if (event.data.includes(">")) {
            event.data = event.data.replaceAll('>', '&gt;');
          }

          if (event.data === '') {
            if (newLineTracker === '') {
              console.log('new line ', resultRef.current)
              event.data = '\n\n';
              newLineTracker = undefined;
            } else {
              newLineTracker = '';
            }
          } else {
            newLineTracker = undefined;
          }

          if (event.data !== '[DONE]') {
            if (codeBlockStarted) {
              if (resultRef.current.substring(resultRef.current.length - 19) === '</code></pre></div>') {
                const sliced = resultRef.current.slice(0, resultRef.current.length - 19);
                resultRef.current = sliced;
              }
            }
            resultRef.current = (resultRef.current ?? '') + event.data ?? '';
            // resultRef.current.match(/```([^`]*)/);
            // console.log('resultRef.current', resultRef.current);
            const respondedChatItem: ChatResponseItem = {
              content: resultRef.current,
              role: 'assistant',
            };


            setChat([...updatedChatList, respondedChatItem]);
          } else {
            // const respondedChatItem: ChatResponseItem = {
            //   content: resultRef.current,
            //   role: 'assistant',
            // };
            resultRef.current = '';
            controller.abort();
          }
        },
        onclose(close: void) {
          resultRef.current = '';
          console.log('Connection closed by the server', close);
          controller.abort();
        },
        onerror(err) {
          resultRef.current = '';
          console.log('There was an error from the server', err);
        },
      });

    } catch (error) {
      console.error('Error sending user message:', error);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const elements = document.getElementsByClassName('message');
  for (let i = 0; i < elements.length; i++) {
    elements[i].scrollTo(0, document.body.scrollHeight);
  }

  useEffect(() => {
    chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
  }, [chatList]);

  return (
    <div className="chat-container">
      <div className="chatbox">
        <div className="messages">
          <div className="messages-container" ref={chatWindowRef}>
            {chatList.length === 0 ? (
              <div className="empty-message">👋 Hey talk to Aifer AI</div>
            ) : (
              chatList.map((chatListItem, index) => (
                <ChatStripe key={index} index={index} chatListItem={chatListItem} />
              ))
            )}
          </div>
        </div>
        <div className="input-section">
          <div className="input-container">
            <input
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type a message..."
            />
            <i className="fas fa-paper-plane" onClick={sendMessage}></i> {/* Sending icon */}
          </div>
        </div>
      </div>
    </div>
  );
};




export default Chat;


export interface ChatResponseItem {
  content: string;
  role: 'user' | 'assistant';
  // loading: boolean;
}

function ChatStripe(props: { index: number, chatListItem: ChatResponseItem }): JSX.Element {
  const elements = document.getElementsByClassName('message');
  for (let i = 0; i < elements.length; i++) {
    elements[i].scrollTo(0, document.body.scrollHeight);
  }

  return (<div
    key={props.index}
    className={`chat-message ${props.chatListItem.role === 'user' ? 'user-message' : 'bot-message'}`}
  >
    <div className='message' dangerouslySetInnerHTML={{ __html: props.chatListItem.content }}></div>
  </div>);
}


